import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import C18Plus from '../../common/icons/18+';

import { TCustomTheme, TPaletteBackground } from '../../../const/theme';
import imgBanner from '../../../assets/img/banner.png';
import imgLogoLottery from '../../../assets/img/logo-lottery.png';

const Block = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  background: (theme.palette.background as TPaletteBackground).banner,
  height: '478px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  position: 'relative',
  boxShadow: '0px 6px 4px 0px #0000001F',
  zIndex: 5
}));

const Wrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  color: theme.palette.common.white,
  fontSize: 15,
  display: 'flex',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridAutoRows: '180px',
  height: 484
}));

type TProps = {
  setOpenModalSubscribe: () => void;
  setOpenModalUnsubscribe: () => void;
};

const Banner: FC<TProps> = ({ setOpenModalSubscribe, setOpenModalUnsubscribe }) => {
  const { t } = useTranslation();

  return (
    <Block>
      <Container>
        <Wrapper>
          <Grid container>
            <Grid item md={ 6 }>
              <img
                alt={ 'banner' }
                src={ imgBanner }
                height={ '100%' }
                style={ {
                  maxHeight: 480,
                  position: 'relative',
                  left: -170,
                  zIndex: -1
              } }
              />
            </Grid>
            <Grid item md={ 6 }>
              <Box position={ 'relative' } display={ 'flex' } flexDirection={ 'column' } alignItems={ 'center' } justifyContent={ 'center' } height={ '100%' }>
                <img
                  style={ {
                    maxWidth: 210,
                    marginRight: 26,
                    marginBottom: 20
                  } }
                  src={ imgLogoLottery }
                />
                <Typography
                  align={ 'center' }
                  variant={ 'h6' }
                  style={ {
                    maxWidth: 465,
                    fontWeight: 200
                  } }
                >
                  { t('block.banner.title') }
                </Typography>
                <Box marginTop={ 2 }>
                  <Box display={ 'flex' } flexDirection={ 'column' } alignItems={ 'center' } justifyContent={ 'center' }>
                    <Button
                      size={ 'large' }
                      onClick={ setOpenModalSubscribe }
                      color="primary"
                      variant="contained"
                      disableElevation
                    >
                      { t('block.banner.button') }
                    </Button>
                    <a
                      onClick={ setOpenModalUnsubscribe }
                      style={ {
                        color: '#ffffff',
                        margin: '16px 10px',
                        borderBottom: '1px dashed',
                        textDecoration: 'none'
                      } }>
                      { t(`block.banner.link`) }
                    </a>
                    <C18Plus
                      style={ {
                        position: 'absolute',
                        right: 35,
                        bottom: 35
                      } }
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Block>
  );
};

export default Banner;
