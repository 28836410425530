import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Modal from '../../components/mobile/Modal';
import Banner from '../../components/mobile/Banner';
import Promo from '../../components/common/Promo';
import Gifts from '../../components/common/Gifts';
import Cup from '../../components/common/Cup';
import Support from '../../components/common/Support';
import Rules from '../../components/common/Rules';

const Mobile = ({ handlerSubscribe, handlerUnsubscribe, handlerLegalNotice, hasVisibleUnsubscribeModal, hasVisibleSubscribeModal }: {
  hasVisibleSubscribeModal: boolean;
  hasVisibleUnsubscribeModal: boolean;
  handlerSubscribe(): void;
  handlerUnsubscribe(): void;
  handlerLegalNotice(): void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <section id={ 'banner' }>
        <Banner setOpenModalSubscribe={ handlerSubscribe }/>
      </section>
      <section id={ 'gifts' }>
        <Container style={ { marginBottom: 32 } }>
          <Card>
            <CardHeader style={ { background: theme.palette.secondary.main } } title={ t('block.gifts.title') }/>
            <CardContent>
              <Cup/>
            </CardContent>
            <CardActions disableSpacing style={ { marginTop: 0 } }>
              <Gifts onClickButton={ handlerSubscribe } onClickLink={ handlerUnsubscribe }/>
            </CardActions>
          </Card>
        </Container>
      </section>
      <section>
        <Promo/>
      </section>
      <section id={ 'rules' }>
        <Container style={ { marginBottom: 32 } }>
          <Card>
            <CardHeader style={ { background: theme.palette.primary.main } } title={ t('block.rules.title') }/>
            <CardContent style={ { marginTop: 0 } }>
              <Rules/>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                onClick={ handlerSubscribe }
                color={ 'secondary' }
                size={ 'small' }
                variant={ 'contained' }
                style={ { marginBottom: 20 } }
              >
                { t(`block.rules.button`) }
              </Button>
              <Button onClick={ handlerLegalNotice } color={ 'primary' } size={ 'small' } variant={ 'contained' }>
                { t(`block.rules.link`) }
              </Button>
            </CardActions>
          </Card>
        </Container>
      </section>
      <section id={ 'help' }>
        <Box display={ 'flex' } flexDirection={ 'column' } alignItems={ 'center' } justifyContent={ 'center' } marginBottom={ 8 }>
          <Typography color={ 'primary' } variant={ 'h6' } style={ { marginBottom: 20 } }>
            { t('block.support.help.title') }
          </Typography>
          <Typography align={ 'center' } style={ { marginBottom: 20, maxWidth: 330 } }>
            { t('block.support.help.desc') }
          </Typography>
          <Support/>
        </Box>
      </section>
      <Modal localeKey={ 'modal.unsubscribe' } open={ hasVisibleUnsubscribeModal } handleClose={ handlerUnsubscribe } />
      <Modal localeKey={ 'modal.subscribe' } open={ hasVisibleSubscribeModal } handleClose={ handlerSubscribe } />
    </>
  );
};

export default Mobile;
